<!-- prettier-ignore -->
<template>
  <div>
    <GlobalSearchCommand />
    <slot />
  </div>
</template>

<style>
/* width */
::-webkit-scrollbar {
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #2e2e2e; */
  background: #1f2937;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #757575; */
  background: #9ca3af;
  border-radius: 10px;
  border: 3px solid #1f2937;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #9e9e9e; */
  background: #9ca3af;
}

/* Scrollbar Styling für Dark Mode */
.dark ::-webkit-scrollbar {
  width: 14px;
}

/* Track */
.dark ::-webkit-scrollbar-track {
  /* background: #2e2e2e; */
  background: #1f2937;
  border-radius: 10px;
}

/* Handle */
.dark ::-webkit-scrollbar-thumb {
  /* background: #757575; */
  background: #9ca3af;
  border-radius: 10px;
  border: 3px solid #1f2937;
}

/* Handle on hover */
.dark ::-webkit-scrollbar-thumb:hover {
  /* background: #9e9e9e; */
  background: #9ca3af;
}
</style>
